"use strict";

const ReferrerKey = "referrer";
const wixDomains = ["wix.com", "wix.wearebookable.com"];
const wordpressDomains = ["wordpress.org"];
const referrers = { wix: "wix", wordpress: "wordpress" };
const queryParams = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});
const referrerParam = queryParams.referrer?.toLowerCase();
const documentReferrer = wixDomains.some(function (domain) {
  return document.referrer.includes(domain);
})
  ? referrers.wix
  : wordpressDomains.some(function (domain) {
      return document.referrer.includes(domain);
    })
  ? referrers.wordpress
  : null;

if (Object.keys(referrers).includes(referrerParam)) {
  localStorage.setItem(ReferrerKey, referrerParam);
} else if (documentReferrer !== null) {
  localStorage.setItem(ReferrerKey, documentReferrer);
} else if (
  document.referrer.indexOf(location.protocol + "//" + location.host) === -1
) {
  localStorage.removeItem(ReferrerKey);
}

window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}
gtag("js", new Date());

gtag("config", "G-7W9FBH2XYR");

document.addEventListener("click", function (event) {
  if (event.target.tagName === "A") {
    if (event.target.href.includes("dashboard.wearebookable.com")) {
      const href = new URL(event.target.href);
      const referrer = localStorage.getItem(ReferrerKey);

      if (referrer) {
        href.searchParams.append(ReferrerKey, referrer);
      }

      event.preventDefault();

      if (
        event.target.href === "https://dashboard.wearebookable.com/register"
      ) {
        gtag("event", "get_started_link_click", {
          link_text: event.target.innerText,
          link_url: event.target.href,
        });
      }

      window.location.href = href.href;
    }
  }
});
